// Pour le moment pas une priorité... on verra par la suite !!!

import { createSlice } from "@reduxjs/toolkit";



export const CommentsActsSlice = createSlice({
    name: 'commentsActs',
    initialState: {},
    reducers: {
        updateComments: (state, action) => {
            state[action.payload.num_comment] = action.payload.comment
        },
    }
})



// Retourne l'array des numeros des commentaires dans le store
export const getCommentsActsInStore = (state) => Object.keys(state.commentsActs).map(Number)

// Retourne le commentaire à partir de son numero
export const getComment = (state, num_comment) => state.commentsActs[num_comment]

