import { createSlice } from "@reduxjs/toolkit";
// import { FilterObject } from '../Util/Objects'



// Ajout sélection d'une activité
const addSelectedActivity = (state, action) => {
    let coureur = action.payload.coureur
    let numero = action.payload.numero

    // Si le coureur a déjà été ajouté
    if (Object.keys(state).includes(coureur)) {
        state[coureur].push(numero)
    } else {
        state[coureur] = [numero]
    }
}

// Ajout sélection de plusieurs activités (activités d'une semaine par exemple)
const addSelectedActivities = (state, action) => {
    let coureur = action.payload.coureur
    let numeros = action.payload.numeros

    // Si le coureur a déjà été ajouté
    if (Object.keys(state).includes(coureur)) {
        state[coureur] = [...new Set(state[coureur].concat(numeros))]
    } else {
        state[coureur] = numeros
    }
}



// Suppression d'une activité dans l'array
const removeSelectedActivity = (state, action) => {
    let coureur = action.payload.coureur
    let numero = action.payload.numero

    let index = state[coureur].indexOf(numero)
    if (index > -1) { // only splice array when item is found
        state[coureur].splice(index, 1) // 2nd parameter means remove one item only
    }
}


// Suppression sélection de plusieurs activités (activités d'une semaine par exemple)
const removeSelectedActivities = (state, action) => {
    let coureur = action.payload.coureur
    let numeros = action.payload.numeros

    // Si le coureur a déjà été ajouté
    if (Object.keys(state).includes(coureur)) {
        state[coureur] = state[coureur].filter(x => !numeros.includes(x))
    }
}



// Suppression d'une activité dans l'array
const cleanSelectedActs1C = (state, action) => {
    state[action.payload] = []
}


const selectWeekActivities = (state, action) => {
    state[action.payload] = []
}




export const SelectActsSlice = createSlice({
    name: 'selectedActs',
    initialState: {},
    reducers: {
        cleanActivities: () => { },
        addSelectedActivity: addSelectedActivity,
        addSelectedActivities: addSelectedActivities,
        removeSelectedActivity: removeSelectedActivity,
        removeSelectedActivities: removeSelectedActivities,
        cleanSelectedActs1C: cleanSelectedActs1C,
        selectWeekActivities: selectWeekActivities,
    }
})





export const isActivitySelected = (state, coureur, numero) => {
    if (Object.keys(state.selectedActs).includes(coureur)) {
        // console.log(coureur, numero, typeof numero, state.selectedActs[coureur].includes(numero))
        return state.selectedActs[coureur].includes(numero)
    } else {
        return false
    }
}

export const getSelectedActs = (state, coureur) => {
    // console.log('Yo')
    if (Object.keys(state.selectedActs).includes(coureur)) {
        return state.selectedActs[coureur]
    } else {
        return []
    }
}


// Retourne les activités sélectionnées du coureur avec planification uniquement
// Utile au déplacement des activités
export const getSelectedPlanOnlyActs = (state, coureur) => {
    // console.log('Yo')
    if (Object.keys(state.selectedActs).includes(coureur)) {
        let numeros = state.selectedActs[coureur]
        return numeros.filter(num => (state.activities[num]['completed'] !== true))
    } else {
        return []
    }
}

// Retourne les activités sélectionnées de tous les coureur avec planification (possiblement planification et complétée)
// Utile à la copie des planifications
export const getSelectedPlanAllCoureurs = (state) => {
    let res = {}
    for (const [coureur, numeros] of Object.entries(state.selectedActs)) {
        let numerosPlan = numeros.filter(num => (state.activities[num]['plan'] === true))
        if (numerosPlan.length > 0) {
            res[coureur] = numerosPlan
        }
    }
    return res
}



export const getNSelectedActs = (state, coureur) => {
    if (Object.keys(state.selectedActs).includes(coureur)) {
        return state.selectedActs[coureur].length
    } else {
        return 0
    }
}

// Retourne un booléen indiquant si on peut lier 2 activités du même jour, une activité seulement planifiée et une autre activité seulement réalisée
// On inclu le sélecteur précédent dans celui-ci ça évite d'en avoir 2 qui seront appelés en même temps !!
export const getInfosSelectedActs = (state, coureur) => {
    if (Object.keys(state.selectedActs).includes(coureur)) {
        let numeros = state.selectedActs[coureur]
        let nSelected = numeros.length
        if (nSelected === 2) {
            // On teste que les activités soient le même jour
            let act1 = state.activities[numeros[0]]
            let act2 = state.activities[numeros[1]]
            // console.log(act1.date_str, act2.date_str, act1.plan, act1.completed, act2.plan, act2.completed, act1, act2)
            if (act1.date_str === act2.date_str) {
                // Si une des 2 activités a les 2 statuts on ne peut pas les lier
                if ((act1.plan & act1.completed) | (act2.plan & act2.completed))
                    return [nSelected, false, null]
                else
                    // On lie les activités si l'une est plan et l'autre complétée
                    return [nSelected, (act1.plan & act2.completed) | (act1.completed & act2.plan), numeros]
            }
            else return [nSelected, false, null]
        } else
            return [nSelected, false, null]
    } else
        return [0, false, null]
}
