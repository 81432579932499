// Save and Load Redux Store to localStorage

import { getRemember } from './../Navigation/Connexion/Connexion'
import { UserConnectedSlice } from './../Redux/UserConnectedSlice'
import { UsersInfosSlice } from './../Redux/UsersInfosSlice'
import { AvatarsSlice } from './../Redux/AvatarsSlice'
import { store } from "./../Redux/Store.js"


// export const saveState = (state) => {
//     try {
//         const serializedState = JSON.stringify(state);
//         localStorage.setItem("state", serializedState);
//     } catch (err) {
//         console.log(err);
//     }
// }


// export const loadState = () => {
//     try {
//         const serializedState = localStorage.getItem("state");
//         if (!serializedState) return undefined;
//         else return JSON.parse(serializedState);
//     } catch (err) {
//         return undefined;
//     }
// }



// Save dans le store d'un Slice
export function saveStorageSlice(name, state) {
    try {
        const serializedState = JSON.stringify(state)

        if (getRemember()) {
            localStorage.setItem(name, serializedState)
        } else {
            sessionStorage.setItem(name, serializedState)
        }

    } catch (err) {
        console.log(err)
    }
}




function loadStorageSlice(name) {
    try {
        let serializedState = (getRemember()) ? localStorage.getItem(name) : sessionStorage.getItem(name)
        if (!serializedState)
            return undefined
        else
            return JSON.parse(serializedState);
    } catch (err) {
        return undefined
    }
}


// Slices à copier du session/localStorage au Redux
const SlicesToLoad = ['userConnected', 'userInfos', 'avatars']

const Reducers = {
    'userConnected': UserConnectedSlice.actions.updateUserConnected,
    'userInfos': UsersInfosSlice.actions.updateUsersInfos,
    'avatars': AvatarsSlice.actions.addAvatars,
}



// On itere sur les différents slice pour ajouter au redux
// La fonction retourne le booléen indiquant si le storage est empty 
export function loadStorageInReduxStore() {
    let storageEmpty = true

    for (const slice of SlicesToLoad) {
        const state = loadStorageSlice(slice)
        // console.log(state)

        if (state !== undefined) {
            storageEmpty = false
            store.dispatch(Reducers[slice](state))
        }
    }

    return storageEmpty
}
