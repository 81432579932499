// Coureur de la page sur lequel on est

import { createSlice } from "@reduxjs/toolkit";




export const CoureurFocusSlice = createSlice({
    name: 'coureurFocus',
    initialState: {},
    reducers: {
        updateCoureurFocus: (state, action) => action.payload
    }
})



export const getCoureurFocus = (state) => state.coureurFocus




