
// import { useState } from 'react'
import { useLocation, Navigate } from "react-router-dom"
import axios from 'axios'

import { clearStorage } from '../Authentification/Storage' 
import { store } from "../../Redux/Store.js"


export default function Logout() {
    const location = useLocation()

    // Clear storage
    clearStorage()
    
    // Clear redux store
    store.dispatch({ type: 'USER_LOGOUT' })

    axios.post("/platform/logout", { 'user': 'user' })
        .then(res => {
            // console.log(res.data)
            console.log('Successful Disconnection.')
        })


    return (
        <Navigate to="/login" state={{ from: location }} replace />
    )
}

















