import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

// import logo from '../../../logos/interdit-velos.png'

import './CrashError.css'


export default function CrashError() {
    const navigate = useNavigate()

    const goPreviousPage = () => {
        navigate(-2)
    }



    return (
        <div className='bloc'>
            <h1>
                Oups ! Erreur dans le chargement de la page...
            </h1>

            <Button variant='contained' onClick={goPreviousPage} >
                Revenir à la page précédente
            </Button>

        </div>
    )
}

















