// Coureur de la page sur lequel on est

import { createSlice } from "@reduxjs/toolkit";




export const UsersInfosSlice = createSlice({
    name: 'usersInfos',
    initialState: {},
    reducers: {
        updateUsersInfos: (state, action) => action.payload
    }
})



export const getUsersInfos = (state) => state.usersInfos
export const getUsersIds = (state) => state.usersInfos.users_Ids
export const getUsersFonction = (state) => state.usersInfos.users_fonctions


export const getUserFromId = (state, Id) => state.usersInfos.Ids_users[Id]
export const getIdFromUser = (state, user) => state.usersInfos.users_Ids[user]
