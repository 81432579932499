// Page de Connexion
// On stocke la variable "remember" dans le localStorage (par défaut "remember" est à true si rien dans le localStorage)
// On récupère "remember" au montage du composant.

// On stocke les variables "user", "token", "droits" dans :
//  --> Le localStorage si "remember" est true (!!! il faudrait le nettoyer si remember est mis à false)
//  --> Le sessionStorage si "remember" est false.



import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, Link } from "react-router-dom"
// import { useSelector, useDispatch } from "react-redux"

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import useWindowDimensions from '../Menu/useWindowDimensions'

import logo from '../../../logos/logo_gfc.png'
import { UserConnectedSlice } from '../../Redux/UserConnectedSlice'
import { UsersInfosSlice } from '../../Redux/UsersInfosSlice'
import { store } from "../../Redux/Store.js"
import { saveStorageSlice } from "../../Redux/SaveLoadStore.js"
import { AvatarsSlice } from "../../Redux/AvatarsSlice.js"
import { CURRENT_SEASON } from '../../Divers/Const.js'
import { swap } from '../../Util/Util.js'

import './Connexion.css'



// Fonction qui retourne la valeur du remember stockée dans le localStorage
// Si jamais elle n'existe pas on retourne true par défaut

export function getRemember() {
    let remember = localStorage.getItem("remember")

    // Si remember n'est pas dans le localStorage il est true par défaut
    if (remember === null) {
        remember = true
    } else {
        remember = JSON.parse(remember)
    }
    return remember
}


export default function Connexion() {
    document.title = "Groupama-FDJ Connexion"
    const navigate = useNavigate()
    const { width } = useWindowDimensions()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(getRemember())
    const [showPassword, setShowPassword] = useState(false)
    const [messageWrongIds, setMessageWrongIds] = useState('')
    const [fontSizeInput, setFontSizeInput] = useState()


    useEffect(() => {
        setFontSizeInput((width > 1000) ? '16px': '12px')
    }, [width])


    const onClick = () => {
        let dataToSend = {
            'email': email,
            'password': password,
            'remember': remember,
        }
        // console.log(dataToSend, remember)

        axios.post("/platform/login", dataToSend)
            .then(res => {
                // Je voulais dezoomer à la connexion mais ça ne marche pas pour le pinch zoom...
                // document.body.style.zoom = '100%'
                
                // console.log(res.data)

                // Si l'accès a été validé par le fontend
                if (res.data.access === true) {

                    // Ajout du user connected dans le store et session/localStorage
                    store.dispatch(UserConnectedSlice.actions.updateUserConnected(res.data.user_connected))
                    saveStorageSlice('userConnected', res.data.user_connected)

                    // Ajout des users_infos dans le store et session/localStorage
                    // Avant cela on ajoute le dico des {'Id': 'user'} à partir de son symétrique {'user': 'Id'}
                    let users_infos = res.data.users_infos
                    users_infos['Ids_users'] = swap(users_infos['users_Ids'])
                    store.dispatch(UsersInfosSlice.actions.updateUsersInfos(users_infos))
                    saveStorageSlice('userInfos', users_infos)

                    // Ajout des avatars dans le store et session/localStorage
                    store.dispatch(AvatarsSlice.actions.addAvatars({ season: CURRENT_SEASON, avatars: res.data.avatars }))
                    saveStorageSlice('avatars', { season: CURRENT_SEASON, avatars: res.data.avatars })

                    // On va à la page d'accueil
                    navigate("/accueil")

                } else {
                    console.log("Connexion impossible !")
                    setMessageWrongIds('❌ Nom d\'utilisateur ou mot de passe incorrect !')
                }
            })
    }

    // Le remember est stocké en localStorage
    const onChangeRemember = (event) => {
        // Si (remember === true) avant changement on supprime le localStorage
        if (getRemember() === true) {
            // console.log('Clear localStorage !')
            localStorage.clear()
        }
        setRemember(x => !x)
        localStorage.setItem("remember", !remember)
    }

    // Styles
    const SxTextField = { width: "500px", fontSize: '18px', '@media (max-width: 1000px)': { width: '100%' } }
    // Je n'arrive pas à changer le fontSize pour les '@media (max-width: 1000px)' ou '@media (maxWidth: 1000px)'...
    // donc j'ai utilisé le hook useWindowDimensions
    // const SxInputLabel = { fontSize: '10px', '@media (maxWidth: 1000px)': { fontSize: '14px' } }
    const SxInputLabel = { fontSize: fontSizeInput}
    const InputProps = { style: SxInputLabel }

    const SxRemember = { fontSize: '20px' }

    return (
        <div className='connexion'>
            <img className='logo_connexion' src={logo} alt='logo_gfc' />

            <div className='connexion_line'>
                Identifiants
            </div>

            <div className='input_login'>
                <TextField sx={SxTextField} size='' id="mail" label="Adresse e-mail" variant="standard"
                    value={email} onChange={(e) => setEmail(e.target.value)}
                    inputProps={InputProps}
                    InputLabelProps={InputProps} />
            </div>

            <div className='input_login'>
                <FormControl sx={SxTextField} variant="standard">
                    <InputLabel sx={SxInputLabel} htmlFor="standard-adornment-password"> Mot de passe </InputLabel>
                    <Input id="standard-adornment-password" type={showPassword ? 'text' : 'password'}
                        inputProps={InputProps}
                        value={password} onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword((show) => !show)} onMouseDown={(e) => e.preventDefault()} >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>


            <span> {messageWrongIds} </span>

            <div className='remember'>
                <FormControlLabel sx={SxRemember} control={<Checkbox checked={remember} onChange={onChangeRemember} color='primary' />}
                    label="Rester Connecté" />
            </div>


            <button className="btn_connexion btn-gfdj" onClick={onClick}>
                Connexion
            </button>
            <Link to="/forgot_password" className='forgot_password'> Mot de passe oublié </Link>
        </div>
    )
}

















