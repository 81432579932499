// Coureur de la page sur lequel on est

import { createSlice } from "@reduxjs/toolkit";
// import { FilterObject } from '../Util/Objects'



// Ajout sélection d'un coureur
const addSelectedCoureur = (state, action) => {
    let coureur = action.payload.coureur
    if (!state.includes(coureur)) {
        state.push(coureur)
    }
}

// Supprime sélection d'un coureur
const removeSelectedCoureur = (state, action) => {
    let coureur = action.payload.coureur
    let index = state.indexOf(coureur)
    if (index > -1) { // only splice array when item is found
        state.splice(index, 1) // 2nd parameter means remove one item only
    }
}



export const SelectCoureursSlice = createSlice({
    name: 'selectCoureurs',
    initialState: [],
    reducers: {
        cleanCoureurs: () => [],
        addSelectedCoureur: addSelectedCoureur,
        removeSelectedCoureur: removeSelectedCoureur,

    }
})



export const isCoureurSelected = (state, coureur) => {
    return state.selectCoureurs.includes(coureur)
}

export const getCoureursSelected = (state) => {
    return state.selectCoureurs
}