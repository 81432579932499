

export function range(start, stop, step = 1) {
    var result = []
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i)
    }
    return result
}



// Fonction pour filtrer l'objet à partir d'un array de keys.
// Si un élement k de keys ne fait pas partie des propriétés de l'objet initial alors on aura res[k] = undefined

// https://stackoverflow.com/questions/38750705/filter-object-properties-by-key-in-es6/38750895?noredirect=1#comment84340622_38750895
// La version est mutable mais ...

export function filterObject(object, keys) {
    return keys.reduce((obj, key) => { obj[key] = object[key]; return obj }, {})
    // return keys.reduce((obj, key) => ({...obj, [key]: object[key]}), {}) // Immutable way
}

// // Test
// let a = {1:1, 2:2, 3:3}
// let newObject = filterObject(a, [1, 2])
// newObject[1] = 2
// console.log(a, newObject)



// Pour inverser/swap un objet
export function swap(json) {
    var ret = {}
    for (var key in json) {
        ret[json[key]] = key
    }
    return ret
}





