// Il s'agit d'un custom Hook pour récupérer le changement de dimensionnement de la fenêtre et avoir un react dynamique par rapport à ce changement
// Trouvé ici : https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs


import { useState, useEffect } from 'react'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}


export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
            // console.log(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}












