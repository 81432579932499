import { Link } from 'react-router-dom';

import logo from '../../../logos/interdit-velos.png'

import './Denied.css';


export default function Denied() {
    

    return (
        <div className='page_interdit'>
            <h1>
                Accès Interdit - <Link to="/accueil">  Retour Accueil </Link>
            </h1>
            <img src={logo} alt='interdit-vélos' className='logo_interdit_velos' />

        </div>
    )
}

















