// Clear session and localStorage in case of deconnexion
// On supprime tout sauf le remember
export function clearStorage(){
    const remember = localStorage.getItem("remember")
    localStorage.clear()
    sessionStorage.clear()

    if (remember !== null) {
        localStorage.setItem("remember", remember)
    }
}






// // Les infos de connexions sont stockées au choix de l'utilisateur ( via l'option se souvenir de moi)

// // Clés pour lesquels il faut parser le string stocké dans le local Storage
// const KEY_JSON_PARSE = ["droits"]


// // Fonction qui retourne les variables stockées dans le storage (localStorage ou sessionStorage selon remember)
// // avec les keys données en paramètres
// const getStorages = (keys) => {
//     // Get Remember
//     // console.log(localStorage.getItem("remember") === null)
//     let remember = JSON.parse(localStorage.getItem("remember"))

//     let ret = {}
//     for (const key of keys) {
//         let item = (remember) ? localStorage.getItem(key) : sessionStorage.getItem(key)
//         ret[key] = (KEY_JSON_PARSE.includes(key)) ? JSON.parse(item) : item
//     }

//     return ret
// }



// // Même fonction mais avec un seul élément
// const getStorage = (key) => {
//     // Get Remember
//     let remember = JSON.parse(localStorage.getItem("remember"))
    
//     let item = (remember) ? localStorage.getItem(key) : sessionStorage.getItem(key)
//     return (KEY_JSON_PARSE.includes(key)) ? JSON.parse(item) : item
// }


// export { getStorages, getStorage }

