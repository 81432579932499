// Import des Avatars/Portraits des utilisateurs

// import { useState, useEffect } from 'react'
// import axios from 'axios'
import { Link } from 'react-router-dom';
// import { useDispatch } from "react-redux";


// import { CURRENT_SEASON } from '../../Divers/Const.js'
// import { store } from "../../Redux/Store.js"
// import { getUserAvatar, AvatarsSlice } from "../../Redux/AvatarsSlice.js"
// import { getUserAndToken } from '../../Redux/UserConnectedSlice'


import empty_avatar from '../../../logos/empty_avatar.png'
import './Effectif.css'





// Trombi ou (empty_avatar + user en option)

function AvatarImg(props) {
    if (['', undefined].includes(props.avatar)) {
        return (
            <div className='container_trombine'>
                <img style={props.style} className='trombine' src={empty_avatar} alt="Empty Avatar" />
                <div className="username_over_trombi"> {props.user} </div>
            </div>
        )
    } else {
        return (
            <img className='trombine' style={props.style} src={props.avatar} alt="avatar" />
        )

    }
}



// Trombine coureur + en option tooltip, link, flag 
// empty_avatar si props.avatar est '' ou undefined

export function Avatar(props) {

    // Composant précédent
    let AvatarPlus = <AvatarImg avatar={props.avatar} user={props.user} style={props.style} />

    // Flag or not
    if (props.flag !== undefined) {
        AvatarPlus =
            <div className='avatar_flag'>
                {AvatarPlus}
                <img className="flag" src={props.flag} alt="flag" />
            </div>
    }

    // Tooltip or not
    //https://www.w3schools.com/howto/howto_css_tooltip.asp
    if (props.toolTip) {
        AvatarPlus =
            // <Tooltip title={props.user} arrow>
            // </Tooltip>
            <div className="tooltip_avatar">
                {AvatarPlus}
                <span className="tooltiptext"> {props.user} </span>
            </div>
    }

    // Link or not
    if (props.link) {
        AvatarPlus =
            <Link className='avatar_link' to={props.link}>
                {AvatarPlus}
            </Link>
    }

    return AvatarPlus
}




// Avatar + nom coureur & fonction en-dessous

export function AvatarPlusInfos(props) {
    // console.log(props.flag === undefined)

    return (
        <div className="container_avatar">
            <Avatar link={`/${props.link}/${props.user.Id}`} flag={props.flag} avatar={props.avatar} user={props.user.user} />
            <div className="user"> {props.user.user} </div>
            <div className="fonction"> {props.user.fonction} </div>
        </div>

    )
}





// Avatar Multi : photos + description en ligne

function SectionAvatars(props) {
    // console.log(props.section)

    return (
        <div>
            <div>
                {(props.section === undefined) ? (
                    false
                ) : (
                    <h2> {props.section} </h2>
                )}
            </div>
            <div className='section'>
                {props.users.map(user =>
                    <AvatarPlusInfos user={user} key={user.user} flag={props.flags?.[user.country]} avatar={props.avatars?.[user.user]} link={props.link} />
                )}
            </div>
        </div>
    )
}





// Composant multi-sections


export default function Effectif(props) {

    if (props.effectifs === undefined) {
        return null
    } else if (Object.keys(props.effectifs).length === 0) {
        return null
    } else {
        return (
            <div>
                {props.effectifs.map((section, i) =>
                    <SectionAvatars section={section.section} users={section.users} key={section.section}
                        ClassNames={props.ClassNames}
                        flags={props.flags} avatars={props.avatars} link={props.link} />
                )}
            </div>
        )
    }
}



// On retient la syntaxe suivante pour itérer sur un objet avec clés et valeurs

// {/* <div>
// {Object.entries(props.effectifs).map(([section, users]) =>
//         <SectionAvatars section={section} users={users} key={section}/>
// )}
// </div> */}