
import { React } from "react" //useState
import { useLocation, Navigate, Outlet } from "react-router-dom" // Route
// import { getStorages } from "./Storage"
import { useNavigate } from "react-router-dom"

import { store } from "../../Redux/Store.js"
import { getUserConnected } from './../../Redux/UserConnectedSlice'
import { loadStorageInReduxStore } from './../../Redux/SaveLoadStore.js'
import Menu from '../Menu/Menu.js'


// Sécurité
// https://stackoverflow.com/questions/48983708/where-to-store-access-token-in-react-js
// https://medium.com/lightrail/getting-token-authentication-right-in-a-stateless-single-page-application-57d0c6474e3




export default function Authentification() {
    const location = useLocation()
    // const navigate = useNavigate()
    let goToConnexion;

    // On récupère le userConnected dans le store, il peut être vide si le user n'est pas connecté
    const userConnected = getUserConnected(store.getState())
    // console.log(userConnected)

    // Si le store est vide
    if (userConnected.user === undefined) {
        // Si les datas du userConnected sont dans le localStorage on charge le localStorage
        goToConnexion = loadStorageInReduxStore()
        // console.log('goToConnexion =', goToConnexion)

    } else {
        goToConnexion = false
    }

    return (
        (goToConnexion) ?
            <Navigate to="/login" state={{ from: location }} replace />
            : <Menu>
                <Outlet />
            </Menu>

    )
}






// Pour le moment on ne conditionne pas l'accès aux composants react avec les droits c'est le backend qui s'en charge
// Si jamais on décide de changer on pourra reprendre l'ancien code suivant...

// const Auth = ({ droits }) => {
//     const location = useLocation()

//     // Get user storage
//     // console.log(localStorage.getItem("user"))
//     const storage = getStorages(['user', 'droits'])
//     const user = storage["user"]
//     let droitsUser = storage["droits"]
//     // console.log(storage)

//     // Intersection droits user et droits du composant
//     droitsUser = (Array.isArray(droitsUser)) ? droitsUser : [] // droits à [] si undefined
//     let intersectionDroits = droitsUser.filter(value => droits.includes(value))
//     // console.log('Droits user = ', droitsUser)
//     // console.log('Droits composants = ', droits)
//     // console.log('Intersection droits = ', intersectionDroits)


//     return (
//         (intersectionDroits.length > 0)
//             ? <Outlet />
//             : (user instanceof String)
//                 ? <Navigate to="/denied" state={{ from: location }} replace />
//                 : <Navigate to="/login" state={{ from: location }} replace />
//     )
// }