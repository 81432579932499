
import { createSlice } from "@reduxjs/toolkit";
import { CURRENT_SEASON } from '../Divers/Const.js'

// Idée : ne pas avoir à recharger toutes les données du backend à chaque fois, notamment les avatars et flags

// Pour toutes les pages où on aura besoin d'un avatar hors des effectif, on ira chercher les avatars dans effectifs[CURRENT_SEASON]


export const AvatarsSlice = createSlice({
    name: 'avatars',
    initialState: { 'flags': {}, 'effectifs': {}, 'effectifsCoureurs': {}, 'trombines': {} },
    reducers: {
        addFlags: (currentState, action) => {
            const withNewFlags = { ...currentState.flags, ...action.payload }
            const newState = { ...currentState, 'flags': withNewFlags }
            return newState
        },
        addEffectifs: (currentState, action) => {
            const withNewEffectifs = { ...currentState.effectifs, ...action.payload }
            const newState = { ...currentState, 'effectifs': withNewEffectifs }
            // console.log(withNewFlags, newState)
            return newState
        },
        addEffectifscoureurs: (currentState, action) => {
            const newState = { ...currentState, 'effectifsCoureurs': action.payload }
            // console.log(withNewFlags, newState)
            return newState
        },
        addAvatars: (currentState, action) => {
            let season = action.payload.season
            let oldTrombines = (Object.keys(currentState['trombines']).includes(season)) ? currentState['trombines'][season] : {}
            // console.log(season, oldTrombines)
            currentState['trombines'][season] = {...oldTrombines, ...action.payload.avatars}

        },
        initAvatars: (currentState, action) => {
            return { 'flags': {}, 'effectifs': {}, 'effectifsCoureurs': {}, 'trombines': {} }
        }
    }
})



// !!!! L'intérêt d'un Selecteur dans useSelector() est de mettre à jour le composant en cas de changement du store

// Si on a besoin de certaines data dans le store sans nécessité de mettre à jour le composant qui appel le store il est inutile d'utiliser un selecteur
// On peut simplement utiliser store.getState...


// Selecteurs :
// Récupère la trombine du user de la CURRENT_SEASON
export const getUserAvatar = (state, user) => state.avatars?.trombines?.[CURRENT_SEASON]?.[user]

// Vrai si l'effectif de la saison en paramètre a été chargée
export const isEffSeasonLoaded = (state, season) => Object.keys(state.avatars.effectifs).includes(season)
// Vrai si l'effectif de la CURRENT_SEASON a été chargée
export const isEffCurrentSeasonLoaded = (state) => Object.keys(state.avatars.effectifs).includes(CURRENT_SEASON)

// Retourne les flags qui sont dans le store
export const getFlags = (state) => state.avatars.flags

// Retourne l'effectif de la saison donnée
export const getEffectifs = (state, season) => {
    return state.avatars.effectifs[season]
}

// Retourne les trombi de la saison donnée
export const getTrombines = (state, season) => {
    return state.avatars.trombines[season]
}
// Retourne les Trombines de la CURRENT_SEASON
export const getTrombinesSeason = (state) => state.avatars.trombines[CURRENT_SEASON]

// Retourne l'effectif des coureurs
export const getEffectifsCoureurs = (state) => state.avatars.effectifsCoureurs
// Retourne vrai si l'effectif des coureurs a été chargé
export const isEffectifsCoureursLoaded = (state) => (state.avatars.effectifsCoureurs.length > 0)



// // Retourne les trombines de la saison en cours, ainsi que les users pour lesquels il faut charger les trombines
// export function getTrombinesAndAvatarsToLoad(state, users) {
//     let trombines = getTrombinesSeason(state)

//     let userAvatarsInStore = Object.getOwnPropertyNames(trombines)
//     let usersAvatarsToLoad = users.filter(x => !userAvatarsInStore.includes(x))

//     return [trombines, usersAvatarsToLoad]
// }























