import MenuBar from './MenuBar'
// import useWindowDimensions from './useWindowDimensions'




export default function Menu({ children }) {
    // const { height, width } = useWindowDimensions()
    // console.log(width, height)

    // if (width < 1000) {
    //     return (
    //         <MenuBarSmall>
    //             {children}
    //         </MenuBarSmall>
    //     )
    // } else {
    return (
        <MenuBar>
            {children}
        </MenuBar>
    )
    // }
}




