// Pour le moment pas une priorité... on verra par la suite !!!

import { createSlice } from "@reduxjs/toolkit";




// Ajout de plusieurs activités
// action = { activities: {numero : {data}, ...} }
const addActivities = (state, action) => {
    return { ...state, ...action.payload.activities }
}


// Update de plusieurs activités
// action = { {numero : {data to update}, ...} }
const updateActivities = (state, action) => {
    for (const [numero, dicoUpdate] of Object.entries(action.payload)) {
        let old_datas = (Object.keys(state).includes(numero)) ? state[numero] : {} // Json vide si on ajoute l'activité'
        state[numero] = { ...old_datas, ...dicoUpdate }
    }
}




export const ActivitiesSlice = createSlice({
    name: 'activities',
    initialState: {},
    reducers: {
        addActivities: addActivities,
        updateActivity: (state, action) => {
            let numero = action.payload.numero
            let dataAct = Object.hasOwn(state, numero) ? state[numero] : {}
            return {
                ...state,
                [numero]: { ...dataAct, ...action.payload.data }
            }
        },
        updateActivities: updateActivities,

    }
})



export const getActivityData = (state, numero) => state.activities[numero]






// export const getActivitiesData = (state, numeros) => {}
