// Pour le moment pas une priorité... on verra par la suite !!!

import { createSlice } from "@reduxjs/toolkit";



export const CommentsSlice = createSlice({
    name: 'CommentsSlice',
    initialState: {},
    reducers: {
        addComments: (state, action) => {
            let Id = action.payload.Id
            let old_numeros = (Object.keys(state).includes(Id)) ? state[Id].numeros : []
            let old_activities = (Object.keys(state).includes(Id)) ? state[Id].activities : {}
            return {
                ...state,
                [Id]: {
                    coureur: action.payload.coureur, // On remet le coureur à chaque fois
                    numeros: [...old_numeros, ...action.payload.numeros], // On redonne à chaque fois la liste (triée) des numéros des activités enregistrées dans le store
                    // numeros : [...new Set([...old_numeros, ...action.payload.numeros])], // Pour éliminer les doublons car en local les appels sont doublés, je ne sais pas si ce sera le cas en prod...
                    activities: { ...old_activities, ...action.payload.activities } // Les activités ajoutées sont nécessairement nouvelles
                }
            }
        },
    }
}) 

